.ant-menu-root {
    &.ant-menu-inline, &.ant-menu-vertical {
        background: @primary-color;
        // border-right: 1px solid @primary-color;
        color: @white;
        a {
            color: @white;
        }
        .ant-menu-submenu-selected,  {
            color: @white;
        }
        .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active {
            color: @white;
        }
        .ant-menu-submenu-title {
            .ant-menu-submenu-arrow {
                &::before, &::after {
                    background: @white;
                }
            }
            &:hover {
                color: @white;
                .ant-menu-submenu-arrow {
                    &::before, &::after {
                        background: @white;
                    }
                }
            }
        }
        .ant-menu-submenu > .ant-menu {
            background-color: @primary-color-light;
        }
        .ant-menu-item > a:hover {
            color: @primary-color;
        }
    }
}
.ant-menu-horizontal {
    > .ant-menu-item-selected {
        color: @white;
        a{
        color: @primary-color;
        }
    }
}

.ant-menu-root.ant-menu-inline, .ant-menu-root.ant-menu-vertical {
    .ant-menu-item-selected {
        a {
            color: @primary-color;
        }
        a:hover {
            color: @primary-color !important;
        }
    }
    .ant-menu-item-selected .ant-menu-item-active {
        a {
            color: @primary-color;
        }
    }
}

.ant-menu-root.ant-menu-inline .ant-menu-item, .ant-menu-root.ant-menu-vertical .ant-menu-item {
    .ant-menu-item-selected {
        a {
            color: @primary-color;
        }
        a:hover {
            color: @primary-color;
        }

    }
}

.ant-menu-inline .ant-menu-selected:after, .ant-menu-inline .ant-menu-item-selected:after {
    display: none;
}
