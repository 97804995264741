/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-menu-root.ant-menu-inline,
.ant-menu-root.ant-menu-vertical {
  background: #4A90E2;
  color: #ffffff;
}
.ant-menu-root.ant-menu-inline a,
.ant-menu-root.ant-menu-vertical a {
  color: #ffffff;
}
.ant-menu-root.ant-menu-inline .ant-menu-submenu-selected,
.ant-menu-root.ant-menu-vertical .ant-menu-submenu-selected {
  color: #ffffff;
}
.ant-menu-root.ant-menu-inline .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-root.ant-menu-vertical .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-root.ant-menu-inline .ant-menu-submenu-active,
.ant-menu-root.ant-menu-vertical .ant-menu-submenu-active {
  color: #ffffff;
}
.ant-menu-root.ant-menu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-root.ant-menu-vertical .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-root.ant-menu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-root.ant-menu-vertical .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: #ffffff;
}
.ant-menu-root.ant-menu-inline .ant-menu-submenu-title:hover,
.ant-menu-root.ant-menu-vertical .ant-menu-submenu-title:hover {
  color: #ffffff;
}
.ant-menu-root.ant-menu-inline .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-root.ant-menu-vertical .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-root.ant-menu-inline .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-root.ant-menu-vertical .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: #ffffff;
}
.ant-menu-root.ant-menu-inline .ant-menu-submenu > .ant-menu,
.ant-menu-root.ant-menu-vertical .ant-menu-submenu > .ant-menu {
  background-color: rgba(96, 157, 230, 0.15);
}
.ant-menu-root.ant-menu-inline .ant-menu-item > a:hover,
.ant-menu-root.ant-menu-vertical .ant-menu-item > a:hover {
  color: #4A90E2;
}
.ant-menu-horizontal > .ant-menu-item-selected {
  color: #ffffff;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #4A90E2;
}
.ant-menu-root.ant-menu-inline .ant-menu-item-selected a,
.ant-menu-root.ant-menu-vertical .ant-menu-item-selected a {
  color: #4A90E2;
}
.ant-menu-root.ant-menu-inline .ant-menu-item-selected a:hover,
.ant-menu-root.ant-menu-vertical .ant-menu-item-selected a:hover {
  color: #4A90E2 !important;
}
.ant-menu-root.ant-menu-inline .ant-menu-item-selected .ant-menu-item-active a,
.ant-menu-root.ant-menu-vertical .ant-menu-item-selected .ant-menu-item-active a {
  color: #4A90E2;
}
.ant-menu-root.ant-menu-inline .ant-menu-item .ant-menu-item-selected a,
.ant-menu-root.ant-menu-vertical .ant-menu-item .ant-menu-item-selected a {
  color: #4A90E2;
}
.ant-menu-root.ant-menu-inline .ant-menu-item .ant-menu-item-selected a:hover,
.ant-menu-root.ant-menu-vertical .ant-menu-item .ant-menu-item-selected a:hover {
  color: #4A90E2;
}
.ant-menu-inline .ant-menu-selected:after,
.ant-menu-inline .ant-menu-item-selected:after {
  display: none;
}
[class*=btn] {
  cursor: pointer;
}
.bg--primary {
  background: #4A90E2;
}
.header {
  padding: 0;
  padding-right: 40px;
  height: 65px;
}
.header .ant-menu {
  background: transparent;
  color: #ffffff;
}
.header .ant-menu .ant-menu-item:hover {
  color: #ffffff;
}
.header__trigger {
  color: #ffffff;
}
.ant-layout-content {
  min-height: auto;
}
.nav_logo {
  padding-left: 12px;
}
